.video {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
        display: none;
        object-fit: contain;
        position: absolute;
        bottom: -50px;
        left: 0;
        z-index: 10;

        @media (min-width: 1500px) {
            display: block;
        }
    }
}

.video__slider {
    height: 30vh;
    width: 100%; border-radius: 30px;

    @include media (get-bp(desktop)) {
        height: 550px; 
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; 
        position: relative;
    }

    .bg{
        background: linear-gradient(180deg, rgba(178, 13, 93, 0.00) 5%, rgba(21, 2, 64, 0.81) 86%);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
    }
} 

.swiper-button-next, .swiper-button-prev{
    background:none ;
}

.play {
    position: absolute;
    z-index: 40;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    i {
        width: 50px;
        height: 50px;
        background: rgba(217, 217, 217, 0.20);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: get-color(light);

        @include media (get-bp(desktop)) {
            width: 80px;
            height: 80px;
            font-size: 32px;
            transition: 400ms;

            &:hover {
                transform: scale(0.9);
            }
        }
    }
} 