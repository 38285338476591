.page-home {
    background-color: get-color(dark);

    .banner__default {
        margin-bottom: 0;

        @include media(get-bp(desktop)) {
            .banner__desktop{
                height: 1050px;
            }
        }
    }

    .banner-info {
        margin-top: -40px;
        z-index: 9;
        position: relative;

        .items-info {
            border-radius: 40px;
            border: 2px solid rgba(255, 255, 255, 0.11);
            background: rgba(255, 255, 255, 0.1);
            box-shadow: 8px 5px 21px 18px rgba(0, 0, 0, 0.39);
            backdrop-filter: blur(12.5px);
            display: grid;
            grid-template-columns: 1fr;
            padding: 32px 65px;
            row-gap: 30px;

            @include media(get-bp(desktop)) {
                padding: 32px 105px;
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: 80px;
            }
        }

        .item-info {
            display: flex;
            align-items: center;
            width: 240px;
            position: relative;

            @include media(get-bp(desktop)) {
                justify-content: center;
            }

            &::after {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 2px;
                background-color: rgba(255, 255, 255, 0.11);
                margin-right: -70px;
                bottom: -17px;

                @include media(get-bp(desktop)) {
                    content: "";
                    display: block;
                    width: 2px;
                    height: 60px;
                    background-color: rgba(255, 255, 255, 0.11);
                    margin-right: -50px;
                    bottom: 0;
                    right: 0;
                }
            }

            &:last-of-type {
                &::after {
                    display: none;
                }
            }

            img {
                display: block;
                width: 50px;
                height: 50px;
                object-fit: cover;
            }

            p {
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                margin-left: 20px;
                color: get-color(light);
            }
        }
    }

    .title {
        margin-bottom: 38px;

        @include media(get-bp(desktop)) {
            margin-bottom: 48px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        h2 {
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 120%;
            color: get-color(light);
            position: relative;
            margin-left: 50px;
            margin-bottom: 0;

            &::before {
                content: "";
                position: absolute;
                display: block;
                width: 40px;
                height: 4px;
                background-color: get-color(primary);
                margin-right: -70px;
                bottom: 16px;
                left: -50px;
            }
        }

        p {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            color: get-color(light);
            max-width: 330px;

            @include media(get-bp(desktop)) {
                text-align: right;
            }
        }
    }

    .about {
        padding-top: 50px;

        .about-info {
            display: grid;
            grid-template-columns: 1fr;
            row-gap: 30px;

            @include media(get-bp(desktop)) {
                grid-template-columns: 3fr 1fr;
                column-gap: 40px;
            }
        }

        .item-number {
            padding: 16px;
            border-radius: 30px;
            border: 2px solid rgba(255, 255, 255, 0.11);
            background: rgba(38, 31, 48, 0.22);

            @include media(get-bp(desktop)) {
                grid-area: 1 / 2 / 2 / 4;
            }

            &:last-of-type {
                @include media(get-bp(desktop)) {
                    grid-area: 2 / 2 / 3 / 4;
                }
            }

            &:first-of-type {
                border: none;
                padding: 40px;
                border-radius: 28px;
                background: linear-gradient(
                    180deg,
                    #c75a9e 0%,
                    #826db0 50%,
                    #3e80c2 100%
                );
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                @include media(get-bp(desktop)) {
                    padding: 40px 70px;
                    grid-area: 1 / 1 / 3 / 1;
                    align-items: start;
                }
            }

            h2 {
                font-size: 32px;
                font-style: normal;
                font-weight: 800;
                line-height: 50.4px;
                text-transform: uppercase;
                color: get-color(light);
                margin-bottom: 0;
                margin-top: 30px;

                @include media(get-bp(desktop)) {
                    font-size: 42px;
                    margin-top: 70px;
                }
            }

            .number {
                h2 {
                    text-align: center;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 48px;
                    margin-bottom: 0;
                    color: get-color(grey-lighter);
                    margin-top: 0;
                }

                p {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 48px;
                    text-align: center;
                    color: get-color(grey-lighter);
                }
            }
        }

        .artis {
            .image {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                img {
                    display: block;
                    width: 50px;
                    height: 50px;
                    border-radius: 100%;
                    margin-left: -12px;
                    border: 2px solid get-color(light);
                    object-fit: cover;

                    &:first-of-type {
                        margin-left: 0;
                    }
                }
            }

            span {
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: get-color(light);
                margin-top: 5px;
            }
        }
    }

    .options {
        padding: 70px 0;

        .options-items {
            display: grid;
            grid-template-columns: 1fr;
            column-gap: 40px;
            row-gap: 40px;

            @include media(get-bp(desktop)) {
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: 40px;
                row-gap: 40px;
            }
        }

        .option {
            border-radius: 30px;
            border: 2px solid rgba(104, 102, 102, 0.3);
            padding: 62px 42px;

            img {
                display: block;
                width: 50px;
                height: 50px;
                object-fit: cover;
            }

            h2 {
                font-size: 32px;
                font-style: normal;
                font-weight: 800;
                line-height: 120%;
                margin-bottom: 0;
                margin-top: 32px;
                color: get-color(light);
            }
        }
    }

    .video {
        padding-bottom: 70px;
    }

    .diversos-estilos {
        padding-bottom: 80px;

        .paginations {
            position: absolute;
            width: 90px;
            left: -9px;
            bottom: 40px;
        }

        .container {
            @include media(get-bp(desktop)) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .content {
            margin-bottom: 30px;

            @include media(get-bp(desktop)) {
                width: 30%;
            }

            .title {
                margin-bottom: 38px;

                @include media(get-bp(desktop)) {
                    margin-bottom: 48px;
                    display: block;
                    align-items: center;
                    justify-content: space-between;
                }

                h2 {
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 120%;
                    color: get-color(light);
                    position: relative;
                    margin-left: 50px;
                    margin-bottom: 20px;

                    &::before {
                        content: "";
                        position: absolute;
                        display: block;
                        width: 40px;
                        height: 4px;
                        background-color: get-color(primary);
                        margin-right: -70px;
                        bottom: 16px;
                        left: -50px;
                    }
                }

                p {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%;
                    color: get-color(light);
                    max-width: 330px;
                    text-align: left;

                    strong {
                        font-weight: 800;
                    }
                }
            }

            .saiba-mais {
                border-radius: 100px;
                background: get-color(bg-btn);
                border-radius: 100px;
                height: 40px;
                padding: 10px 20px;
                color: get-color(light);
                text-transform: uppercase;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;

                &:hover {
                    background: get-color(light);
                    color: get-color(primary);
                }
            }
        }

        .swiper-container {
            position: relative;

            @include media(get-bp(desktop)) {
                width: 65%;
                margin: 0;
            }

            .swiper-wrapper {
                display: flex;
                padding-bottom: 80px;
            }

            .swiper-slide {
                border-radius: 15px;
                text-align: center;
                color: get-color(light);
                transition: transform 0.3s ease;

                img {
                    display: block;
                    width: 100%;
                    height: 410px;
                    object-fit: cover;
                    border-radius: 30px;
                    position: relative;
                }

                .bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(
                        180deg,
                        rgba(178, 13, 93, 0) 5%,
                        rgba(21, 2, 64, 0.81) 86%
                    );
                    border-radius: 30px;
                    z-index: 9;
                }

                p {
                    position: absolute;
                    bottom: 20px;
                    z-index: 91;
                    left: 50%;
                    transform: translate(-50%, -10%);
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 120%;
                    width: 100%;
                    overflow: hidden;
                }
            }
        }
    }

    .values {
        margin-bottom: 100px;

        .values-info {
            display: grid;
            row-gap: 30px;
            grid-template-columns: 1fr;

            @include media(get-bp(desktop)) {
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: 40px;
            }
        }

        .item-values {
            width: 337px;
            border-radius: 30px 30px 0px 0px;
            border-top: 2px solid rgba(104, 102, 102, 0.3);
            border-right: 2px solid rgba(104, 102, 102, 0.3);
            border-left: 2px solid rgba(104, 102, 102, 0.3);
            padding: 60px 30px 10px 40px;
            
            @include media(get-bp(desktop)) { 
                width: 386px;
                padding: 60px 60px 10px 60px;
            }

            p {
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 28.8px;
                color: get-color(light);
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                margin-bottom: 10px;
            }

            h2 {
                font-family: "Open Sans";
                font-size: 72px;
                font-style: normal;
                font-weight: 800;
                line-height: 86.4px;
                margin-bottom: 30px;
                -webkit-text-stroke-width: 1.5px;
                -webkit-text-stroke-color: get-color(primary);
                color: get-color(dark);
            }

            ul {
                padding: 0;
                margin: 0;
                height: 120px;
                overflow: auto;
                
                &::-webkit-scrollbar {
                    width: 8px;
                }

                &::-webkit-scrollbar-track {
                    background: get-color(light);
                    border-radius: 10px;
                }
                
                &::-webkit-scrollbar-thumb {
                    background: get-color(bg-btn);
                    border-radius: 10px;
                } 

                li {
                    list-style: none;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    p {
                        margin-left: 10px;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 27px;
                        color: get-color(light);
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        margin-bottom: 0;
                    }
                }
            }
        }

        .detalhe {
            position: relative;

            svg{
                display: block;
                width: 337px;

                @include media(get-bp(desktop)) {
                    width: 100%;
                }
            }
        }

        .linha {
            position: absolute;
            top: 49px;
            left: 26px; 

            svg{
                display: block;
                width: 287px;

                @include media(get-bp(desktop)) {
                    width: 100%;
                }
            }
        }

        .value-buy {
            margin: 0;
            width: 337px;
            border-radius: 0px 0px 30px 30px;
            border-right: 2px solid rgba(104, 102, 102, 0.3);
            border-bottom: 2px solid rgba(104, 102, 102, 0.3);
            border-left: 2px solid rgba(104, 102, 102, 0.3);
            padding-bottom: 40px;
            margin-top: 0px;

            @include media(get-bp(desktop)) { 
                width: 386px;
            }

            .buy {
                border-radius: 100px;
                background: get-color(bg-btn);
                border-radius: 100px;
                height: 40px;
                padding: 13px 20px 10px 20px;
                color: get-color(light);
                text-transform: uppercase;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
                display: flex;
                justify-content: center;
                max-width: 200px;
                margin: auto;

                &:hover {
                    background: get-color(light);
                    color: get-color(primary);
                }
            }
        }
    }
}
