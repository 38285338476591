.footer-container {
    background-color: get-color(dark);
    padding-top: 40px;
    padding-bottom: 40px;
    color: get-color(light);

    .container {
        @include media(get-bp(desktop)) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
}

.footer-container__row {
    width: 100%;

    &:first-of-type {
        @include media(get-bp(desktop)) {
            display: grid;
            grid-template-columns: 200px 1fr 200px;
            align-items: flex-start;
            grid-column-gap: 30px;
        }
    }

    &:last-of-type {
        padding-top: 30px;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
}

.footer-wrapper{
    border-top: 1px solid get-color(grey-dark);
}

.footer-container__first-column {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    @include media(get-bp(tablet-portrait)) {
        align-items: center;
    }

    @include media(get-bp(desktop)) {
        align-items: flex-start;
    }
}

.footer-logo {
    margin-bottom: 20px;

    @include media(get-bp(tablet-portrait)) {
        margin-bottom: 50px;
    }

    img {
        display: block;
        width: 100%;
        max-width: 120px;
        object-fit: contain;
        margin-bottom: 25px;
    }

    .social-listing {
        justify-content: flex-start;

        .social-listing__item {
            color: get-color(light);
        }

        @include media(get-bp(tablet-portrait)) {
            justify-content: center;
        }

        @include media(get-bp(desktop)) {
            justify-content: flex-end;
        }

        li + li {
            margin-left: 6px;
        }
    }
}

.footer-container__second-column {
    @include media(get-bp(tablet-portrait)) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: flex-start;
        margin: 0 auto;
        width: 100%;
        max-width: 650px;
    }

    @include media(get-bp(desktop)) {
        max-width: unset;
        margin: unset;
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    ul {
        margin: 0 0 30px;
        list-style: none;
        padding-left: 0;
        width: max-content;
        max-width: 160px;

        &:nth-child(1) {
            @include media(get-bp(desktop)) {
                justify-self: center;
            }
        }

        &:nth-child(2) {
            @include media(get-bp(tablet-portrait)) {
                justify-self: center;
            }

            @include media(get-bp(desktop)) {
                justify-self: center;
                // 3 colunas
                margin: 0 180px;

                // 4 colunas
                // margin: 0 70px;
            }
        }

        &:nth-child(3) {
            @include media(get-bp(tablet-portrait)) {
                justify-self: flex-end;
            }

            @include media(get-bp(desktop)) {
                // 4 colunas
                // margin-right: 70px;
            }
        }

        li {
            margin-bottom: 14px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: get-color(light);

            &:first-of-type {
                font-weight: 400;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }
}

.footer-container__third-column {
    margin-bottom: 30px;

    @include media(get-bp(tablet-portrait)) {
        display: flex;
        justify-content: end;
    }
}

.footer-container__copyright {
    @include media(get-bp(tablet-portrait)) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    @include media(get-bp(desktop)) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }

    p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 15px;

        @include media(get-bp(desktop)) {
            margin-bottom: 0;
        }

        a {
            text-decoration: underline;
        }
    }

    img {
        display: block;
        width: 100%;
        max-width: 60px;
        filter: brightness(0) invert(1);
    }
}
