.modal-login {
    padding: 0 !important;
    left: unset !important;

    .modal__box {
        height: 100%;
        background: get-color(dark);
        color: get-color(light);
    }

    .modal__bg{
        background-color: rgba(21, 74, 148, 0.35);
    }

    form {
        width: 100%;
        margin-bottom: 60px;

        @include media(get-bp(desktop)) {
            margin-bottom: 0;
            padding: 0;
            border-radius: 6px;
            margin: 0;
        }

        .field {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            margin-bottom: 20px;

            &:focus-within {
                label {
                    color: get-color(primary);
                }

                input {
                    border: 1px solid get-color(secondary);
                }

                textarea {
                    border: 1px solid get-color(primary);
                }
            }

            span {
                width: 100%;
            }

            .wpcf7-list-item {
                margin: 0;
            }

            input {
                width: 100%;
                height: 40px;
                border-radius: 6px;
                border: none;
                outline: none;
                padding: 0 1rem;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                color: get-color(light);
                background-color: rgba(206, 212, 218, 0.5);
            }

            textarea {
                width: 100%;
                height: 120px;
                border-radius: 6px;
                border: none;
                resize: none;
                outline: none;
                padding: 1rem;
                font-size: 14px;
                color: get-color(dark);
                background-color: get-color(grey-dark);
            }

            input[type="checkbox"] {
                width: 16px;
                height: 16px;
                margin-right: 6px;
            }

            &.field--checkbox {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: row;

                label a {
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }

        .field--submit {
            margin-top: 30px;

            input {
                width: 100%;
                height: 40px;
                padding: 1rem 4rem;
                background: get-color(bg-btn);
                color: get-color(light);
                border: none;
                border-radius: 6px;
                letter-spacing: 2px;
                font-weight: 900;
                text-transform: uppercase;
                font-size: 16px;
                transition: 400ms;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.28px;
                text-transform: uppercase;

                &:hover {
                    filter: brightness(0.9);
                }
            }
        }

        .items-help {
            display: flex;
            justify-content: space-between;

            a {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }

            #showCadastro {
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 140%;
                color: get-color(primary);
            }
        }
    }

    .modal__body {
        width: 330px;

        @include media(get-bp(desktop)) {
            width: 400px;
        }
    }

    .login {
        .back {
            margin-bottom: 48px;
            display: block;
        }

        span {
            display: block;
            margin-bottom: 30px;
        }
    }

    .cadastre {
        > .two-column {
            margin-bottom: 48px;
        }

        span {
            display: block;
            margin-bottom: 30px;
        }
    }

    h4 {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    span {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }

    .field--checkbox {
        label {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }
    }

    .modal__header {
        border: none;
    }

    .modal__box { 
        padding: 20px;

        @include media(get-bp(desktop)) {
            padding: 40px 70px;
        }
    }

    .access-text {
        font-size: 16px;
        margin-bottom: 10px;
        color: get-color(third);
    }

    .access-link {
        font-size: 18px;
        margin-bottom: 20px;
        display: block;
        color: get-color(primary);
        font-weight: bold;
        text-decoration: none;
    }

    .icons {
        display: flex;
        justify-content: center;
        gap: 20px;
    }

    .icons a {
        display: inline-block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }

    .icons img {
        width: 24px;
        height: 24px;
    }

    .access {
        display: flex;
        margin: auto;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;

        .access-text{
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
        }

        .access-link{
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }

        .icons{
            i{
                color: get-color(primary);
            }
        }
    }
}

.modal {
    z-index: 9999 !important;
}

.hidden {
    display: none;
}
