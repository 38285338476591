//posicionamento do menu fixo no topo
.main-header-bundle {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
}

.main-header {
    background-color: rgba(#000, 0);
    color: get-color(dark);

    h1 {
        margin: 0;
    }
    &.change-color {
        background-color: rgba(#000, 0.2);
        backdrop-filter: blur(9px);
        box-shadow: 0 0.2rem 0.7rem rgba(#000, 0.1);
    }
}

.menu-buttons {
    @include media(get-bp(desktop)) {
        display: flex;
        align-self: center;
    }
    a {
        padding: 5px 15px;
        display: block;
        background-color: get-color(primary);
        margin-bottom: 20px;
        text-align: center;
        border-radius: 5px;
        color: get-color(secondary);
        font-weight: 600;
        transition: 400ms;

        @include media(get-bp(desktop)) {
            margin-right: 20px;
            margin-bottom: 0;
        }
        &:hover {
            background-color: get-color(secondary);
            color: get-color(light);
        }
    }
}

//menu desktop
.desktop-menu {
    display: none;
    @media (min-width: 1200px) {
        display: block;
    }
    .container {
        display: flex;
        justify-content: space-between;
    }

    .main-header__logo-container {
        width: 120px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-right: 40px;

        a {
            display: flex;
        }
    }

    .main-header__logo {
        width: 100%;
        height: 100%;
        display: inline-block;
        object-fit: contain;
    }

    .header-content {
        display: flex;
        align-items: center;
        flex: 1;
    }
    .social-listing {
        margin-left: 20px;
        .social-listing__item {
            color: get-color(light);
        }
    }

    .menu-container {
        flex: 1;

        @include media(get-bp(desktop)) {
            display: flex;
            height: 100%;
            justify-content: space-between;
        }
        .menu-container__listing {
            justify-content: flex-end;
            align-items: center;

            > li {
                position: relative;

                > .submenu {
                    display: grid;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    bottom: 0;
                    transform: translateY(100%);
                    position: absolute;
                    background-color: get-color(light);
                    color: get-color(secondary);
                    opacity: 0;
                    visibility: hidden;
                    max-height: 200px;
                    width: 200px;
                    padding: 10px;
                    row-gap: 10px;
                    overflow: auto;
                    transition: 400ms;
                    z-index: 999;

                    li:hover {
                        text-decoration: underline;
                    }
                    &::-webkit-scrollbar {
                        width: 5px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: get-color(grey-light);
                    }
                    &::-webkit-scrollbar-thumb:hover {
                        background-color: get-color(grey-dark);
                    }
                }
                &:hover {
                    > .submenu {
                        visibility: visible;
                        opacity: 100%;
                    }
                }
            }
            > li {
                transition: 400ms;

                @include media(get-bp(desktop)) {
                    margin-right: 20px;
                    display: flex;
                }
                &:hover {
                    color: get-color(primary);
                }
            }
            a {
                align-self: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                height: 100%;
                display: flex;
                align-items: center;
            }
        }

        .singin {
            border-radius: 100px;
            border: 1px solid get-color(light);
            height: 40px;
            padding: 10px 20px;

            &:hover {
                background: get-color(light);
            }
        }

        .buy {
            border-radius: 100px;
            background: get-color(bg-btn);
            border-radius: 100px;
            height: 40px;
            padding: 10px 20px;

            &:hover {
                background: get-color(light);
            }
        }

        .menu-item {
            margin-left: 30px;
            padding-bottom: 35px;
            margin-top: 40px;
            border-bottom: 3px solid transparent;
            position: relative;
            &:hover {
                text-decoration: underline;
                .menu-item__submenu {
                    display: block;
                }
            }
        }
        .menu-item__submenu {
            position: absolute;
            left: 0;
            width: 130px;
            font-size: 14px;
            display: none;
            flex-wrap: wrap;
            z-index: 1;
            top: 60px;
            margin: 0;
            a {
                font-size: 12px;
                background-color: get-color(primary);
                padding: 10px;
                display: block;
                width: 100%;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .burger-icon-container {
        display: block;
        margin-left: 30px;
        &.is-active {
            .burger-icon {
                background-color: transparent;
            }
        }
        .burger-icon {
            background-color: get-color(primary);

            &::before {
                background-color: get-color(primary);
            }

            &::after {
                background-color: get-color(primary);
            }
        }
    }
}

//menu mobile
.mobile-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    @media (min-width: 1200px) {
        display: none;
    }
    .mobile-logo {
        width: 100px;
        margin: 0;

        a {
            display: flex;
            margin: 10px 0;
        }
    }

    //controle da cor do burger menu (acesso facil)
    .burger-icon {
        background-color: get-color(primary);
        &::before {
            background-color: get-color(primary);
        }

        &::after {
            background-color: get-color(primary);
        }
    } 
}

//espacamento do conteudo pra barra do topo
.main-header-clearance {
    padding-top: var(--main-header-height);
    display: none;
}

.js-open-submenu-mobile {
    display: flex;
    align-items: center;
    transition: 400ms;

    @include media(get-bp(desktop)) {
        display: none;
    }
}
